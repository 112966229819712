.ContentListSlider__slider--carousel {
  --content-list-slider-side-gradient-width: 64px;

  @media (--laptop) {
    width: calc(
      (var(--wrapperWidth) * 2) + (2 * 10px) + (var(--content-list-slider-side-gradient-width) * 2)
    ); /* 10px is a gap between slides on a desktop */

    margin-inline: 50% 0;
    transform: translateX(-50%);
  }

  & .ContentListSlider__sliderTrack {
    user-select: auto;
    /* stylelint-disable-next-line declaration-no-important */
    padding-left: var(--spacing-xxl) !important;
    /* stylelint-disable-next-line declaration-no-important */
    padding-right: var(--spacing-xxl) !important;

    @media (--laptop) {
      /* stylelint-disable-next-line declaration-no-important */
      padding-left: calc((100% - var(--wrapperWidth)) / 2 - 1px) !important;
      /* stylelint-disable-next-line declaration-no-important */
      padding-right: calc((100% - var(--wrapperWidth)) / 2 - 1px) !important;

      &::before,
      &::after {
        content: "";
        position: absolute;
        top: 0;
        z-index: var(--z-index-content-list-slider-side-gradient);
        width: var(--content-list-slider-side-gradient-width);
        height: 100%;
        transition: all var(--transition-duration);
      }

      &::before {
        left: 0;
        background: linear-gradient(
          90deg,
          var(--base-grey-white) 0%,
          color(var(--base-grey-white) a(0%)) 100%
        );
      }

      &::after {
        right: 0;
        background: linear-gradient(
          270deg,
          var(--base-grey-white) 0%,
          color(var(--base-grey-white) a(0%)) 100%
        );
      }
    }

    & .ContentListSlider__slide {
      /* stylelint-disable-next-line selector-class-pattern */
      &:not(.is-active) {
        &,
        & a {
          user-select: none;
          cursor: default;
        }
      }
    }

    & .ContentListSlider__pagination {
      @mixin wrapper;

      margin-top: calc(var(--spacing-xxs) + (var(--spacing-xxxs) / 2));
    }

    & .ContentListSlider__arrows {
      &.ContentListSlider__arrows--center {
        max-width: calc(
          var(--wrapperWidth) + ((var(--wrapper-stretched-width) - var(--wrapperWidth)) / 2) +
            var(--content-list-slider-arrow-size)
        );
      }
    }
  }
}
