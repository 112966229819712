.Tiles__list {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.Tiles--withGap {
  & .Tiles__list {
    flex-wrap: wrap;
    gap: calc(var(--spacing-xxs) + var(--spacing-xxxs));

    @media (--tablet) {
      gap: calc(var(--spacing-xs) + var(--spacing-xxxs));
    }
  }
}

.Tiles--3inRow {
  & .Tile {
    width: 333px;

    & .Tile__image {
      height: 410px;
    }
  }

  & .Tile--small {
    width: 333px;

    & .Tile__image {
      height: 333px;
    }
  }
}

.Tiles--4inRow {
  & .Tile {
    width: 245px;

    & .Tile__image {
      height: 309px;
    }

    & button {
      width: 100%;
      text-align: center;
    }
  }

  & .Tile--small {
    width: 245px;

    & .Tile__image {
      height: 245px;
    }
  }
}
