.ImageWithFacts {
  background-color: var(--grayColor_tint_2);

  & .ImageWithFacts__title {
    margin-block: 0 var(--spacing-xs);
  }

  & .ImageWithFacts__description {
    @mixin typographyMobileDefaultXLarge;

    margin-block: 0 var(--spacing-s);
    color: #011520;

    @media (--tablet) {
      @mixin typographyDesktopDefaultXLarge;

      margin-block: 0 var(--spacing-xl);
    }
  }
}
