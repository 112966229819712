.ContentList {
  & .Section__title {
    margin: 0 0 calc(var(--spacing-s) + var(--spacing-xxxs));
  }
}

.ContentList__grid {
  @media (--mobileSmall) {
    column-gap: var(--spacing-xs);
  }

  @media (--tablet) {
    margin: 0;
  }

  @media (--laptop) {
    column-gap: var(--spacing-s);
  }

  & > li {
    margin: 0 0 var(--spacing-xs);
  }
}

.ContentList__grid--items1 {
    @media (--laptopMedium) {
      grid-template-columns: minmax(0, 1fr);
      column-gap: var(--spacing-m);
    }
  }

.ContentList__grid--items2 {
  @media (--laptopMedium) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: var(--spacing-m);
  }
}

.ContentList__grid--items3 {
  @media (--laptopMedium) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    column-gap: var(--spacing-m);
  }
}
