.ProductContainsSection__title {
  margin-block: 0 var(--spacing-xs);
}

.ProductContainsSection__description {
  color: #011520;

  @media (--tablet) {
    @mixin typographyDesktopDefaultXLarge;
    margin-block: 0 var(--spacing-s);
  }
}
