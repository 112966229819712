.ProductContains {
  display: grid;
  gap: var(--spacing-xs);

  @media (--tablet) {
    gap: var(--spacing-s);
  }

  &.ProductContains--isExpanded {
    & .ProductContains__showMoreButton {
      & svg {
        transform: rotate(180deg);
      }
    }
  }
}

.ProductContains__products {
  display: grid;
  gap: calc(var(--spacing-xxs) + var(--spacing-xxxs));

  @media (--tablet) {
    gap: calc(var(--spacing-xs) + var(--spacing-xxxs));
  }
}

.ProductContains__mainImage {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-color: #ECECEC;
  overflow: hidden;

  & img {
    object-fit: contain;
    height: 353px;
  }
}

.ProductContains__showMoreButton {
  justify-self: center;

  & svg {
    transition: transform .2s;
  }

  @media (--mobileSmallOnly) {
    width: 100%;
    justify-content: center;
  }
}

.ProductContains__list {
  display: grid;
  list-style: none;
  margin: 0;
  padding: 0;
  grid-template-columns: repeat(2, 1fr);
  gap: calc(var(--spacing-xxs) + var(--spacing-xxxs));
  transition: max-height 0.3s;
  overflow: hidden;

  @media (--tablet) {
    grid-template-columns: repeat(4, 1fr);
    gap: calc(var(--spacing-xs) + var(--spacing-xxxs));
  }
}

.ProductContains__item {
  display: block;
  background-color: #ECECEC;
  border-radius: 5px;
}

.ProductContains__itemLink {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
  padding: calc(var(--spacing-xxs) + (var(--spacing-xxxs) / 2));
  color: var(--base-grey-black-100);
  height: 100%;
  @mixin focusVisible var(--outlineColorSecondary), 2px, -2px;

  @media (--mobileSmall) {
    padding: calc(var(--spacing-xxs) + var(--spacing-xxxs) + (var(--spacing-xxxs) / 2));
    gap: 0;
  }
}

.ProductContains__itemImage {
  @media (--mobileSmall) {
    margin-block: 0 var(--spacing-s);
  }

  & img {
    aspect-ratio: 1 / 1;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.ProductContains__itemName {
  @mixin typographyMobileStrongLarge;

  @media (--mobileSmall) {
    @mixin typographyDesktopStrongLarge;
  }
}

.ProductContains__itemDescription {
  @mixin typographyMobileDefaultMedium;

  @media (--mobileSmall) {
    @mixin typographyDesktopDefaultMedium;
    margin-block: var(--spacing-xxs) 0;
  }
}
