.ProductTile {
  & .Tile__title {
    margin-bottom: 0;
  }

  &.Tile--small {
    & .Tile__title {
      margin-bottom: 0;
    }
  }
}
