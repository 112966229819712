@define-mixin largeBlockSpacingDiscovery {
  padding-block: var(--spacing-m);

  @media (--tablet) {
    padding-block: var(--spacing-xxxl); /* 64px */
  }

  @media (--laptop) {
    padding-block: var(--spacing-xxxxxxxxl); /* 128px */
  }

  @media (--desktop) {
    padding-block: 11rem; /* 176px */
  }
}

@define-mixin smallBlockSpacingDiscovery {
  padding-block: var(--spacing-s);

  @media (--laptop) {
    padding-block: var(--spacing-m);
  }
}
