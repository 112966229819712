.CallToActionButton {
  &:not(.CallToActionButton--colored) {
    &.CallToActionButton--primary {
      @mixin buttonPrimaryTealFill;
    }

    &.CallToActionButton--secondary {
      @mixin buttonSecondaryThemeColorWhite;
    }
  }
}