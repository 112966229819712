.Section {
  @mixin wrapper;
}

.Section--blue {
  background-color: var(--accent-blue-accent-darker);
  color: var(--accent-blue-accent-lighter);
}

.Section--stretched {
  @mixin wrapperStretched;
}

.Section--fullWidth {
  @mixin resetWrapper;
}

/*TODO I added global Section--mediumVerticalPaddings. We can migrate below class to it.*/
.Section--verticalPaddings {
  @mixin blockSpacingMedium;
}

.Section--largeVerticalPaddings {
  @mixin largeBlockSpacingDiscovery;
}

.Section--smallVerticalPaddings {
  @mixin smallBlockSpacingDiscovery;
}

.Section__title {
  @mixin typographyMobileStrongXXLarge;

  margin-block: 0 var(--spacing-m);

  @media (--tablet) {
    @mixin typographyDesktopStrongXXLarge;

    margin-block: 0 var(--spacing-m);
  }
}

.Section__title--condensed {
  margin-block: 0 var(--spacing-xs);

  @media (--tablet) {
    margin-block: 0 var(--spacing-xs);
  }
}

.Section__description {
  @mixin typographyMobileDefaultLarge;

  margin-block: 0 var(--spacing-s);

  @media (--tablet) {
    font-size: 1.125rem;
    line-height: 1.5;
    letter-spacing: -0.27px;
    margin-block: 0 var(--spacing-m);
  }
}
