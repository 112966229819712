.ProductsRange {
  & .ContentListSlider__slider--3inRow {
    & .ContentListSlider__slide {
      @media (--mobileOnly) {
        width: 257px;
      }

      & .Tile__image {
        @media (--mobileOnly) {
          height: 333px;
        }
      }
    }
  }
}
