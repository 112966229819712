/* TODO: To be removed after changing postcss-custom-properties option to "preserve: true" */
.ContentListSlider__sliderTrack {
  /* stylelint-disable-next-line declaration-no-important */
  padding-left: var(--wrapperPadding) !important;
  /* stylelint-disable-next-line declaration-no-important */
  padding-right: var(--wrapperPadding) !important;

  @media (--laptop) {
    /* stylelint-disable-next-line declaration-no-important */
    padding-left: max(var(--wrapperPadding), calc((100% - var(--wrapperWidth)) / 2 - 1px)) !important;
    /* stylelint-disable-next-line declaration-no-important */
    padding-right: max(var(--wrapperPadding), calc((100% - var(--wrapperWidth)) / 2 - 1px)) !important;
  }
}

.ContentListSlider__arrows {
  &.ContentListSlider__arrows--center {
    & + .ContentListSlider__sliderInner {
      & .ContentListSlider__pagination {
        @mixin wrapper;
      }
    }
  }
}
