.DownloadsSection__linksList {
  margin: 0;
  padding: 0;
  list-style: none;
  display: grid;
}

.DownloadsSection__linksList--wide {
  @media (--mobileSmall) {
    grid-template-columns: repeat(2, 1fr);
    column-gap: calc(var(--spacing-xs) + var(--spacing-xxs));
  }

  @media (--laptop) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.DownloadsSection__linksItemLinkContent {
  display: flex;
  align-items: center;
  gap: calc(1.25 * var(--spacing-xxs));
}

.DownloadsSection__linksItemLink {
  @mixin typographyMobileDefaultMedium;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-xxs);
  width: 100%;
  border-radius: 2px;
  border: 1px solid transparent;
  transition: border-color 0.1s;
  color: var(--base-grey-black-100);

  @media (--tablet) {
    @mixin typographyDesktopDefaultMedium;
  }

  &:hover {
    border-color: var(--base-grey-medium-100);
    background-color: var(--base-grey-light-100);

    & > .DownloadsSection__iconBtn--attachment {
      background: var(--accent-blue-accent-dark);
      border-color: transparent;
    }

    & > .DownloadsSection__iconBtn--external {
      background: var(--base-grey-dark-80);
      border-color: transparent;
    }
  }

  &:active {
    border-color: var(--accent-blue-accent-darker);
  }

  &:active,
  &:focus-visible {
    & > .DownloadsSection__iconBtn--attachment {
      background: var(--accent-blue-accent-darker);
      border-color: transparent;
    }

    & > .DownloadsSection__iconBtn--external {
      background: var(--base-grey-black-100);
      border-color: transparent;
    }
  }

  @mixin focusVisible var(--outlineColorSecondary), 2px, -2px;

  &[aria-disabled="true"],
  &:disabled {
    color: var(--base-grey-light-100);
    border-color: transparent;

    & > .DownloadsSection__iconBtn--attachment,
    & > .DownloadsSection__iconBtn--external {
      background: var(--base-grey-medium-100);
      border-color: transparent;
    }
  }
}

.DownloadsSection__iconBtn {
  @mixin buttonDefaultSizeIconOnly;

  align-self: flex-start;

  &.DownloadsSection__iconBtn--attachment {
    @mixin buttonPrimaryBlueFill;
  }

  &.DownloadsSection__iconBtn--external {
    @mixin buttonBaseFill;

    & > svg {
      stroke: initial;
    }
  }
}