.SupportSection {
  @media (--laptop) {
    padding-block: var(--spacing-xxxxl);
  }

  & .ContentList {
    @media (--mobileAndTablet) {
      & .Tile {
        & .Tile__title {
          @mixin typographyMobileStrongXXLarge;
          margin-block: 0 var(--spacing-xs);
        }

        & .Tile__description {
          @mixin typographyMobileDefaultXLarge;
        }

        & .Tile__link {
          @mixin typographyMobileStrongLarge;
        }

        & .Tile__links {
          gap: calc(var(--spacing-xxs) + (var(--spacing-xxxs) / 2));
        }

        @media (--mobileSmallOnly) {
          & .ButtonOutlined {
            display: flex;
            justify-content: center;
            width: 100%;
          }
        }
      }
    }
  }
}
