.ImageWithFactsViewer {
  @mixin kogGrid;

  @media (--mobileOnly) {
    gap: var(--spacing-s);
  }
}

.ImageWithFactsViewer__container {
  @mixin kogTabletColumn 5;
  @mixin kogLaptopColumn 4;

  @media (--mobileOnly) {
    order: 1;
  }
}

.ImageWithFactsViewer__list {
  display: flex;
  flex-direction: column;
  gap: calc(var(--spacing-xxs) + var(--spacing-xxxs) + (var(--spacing-xxxs) / 2));
  list-style: none;
  margin: 0;
  padding: 0;
}

.ImageWithFactsViewer__listItem {
  display: block;
}

.ImageWithFactsViewer__button {
  @mixin resetButtonStylesNoOverride;

  font-size: 1.5rem;
  line-height: 1.5rem;
  cursor: pointer;
  @mixin focusVisible var(--outlineColorSecondary);

  color: #32352f;
  transition: 0.3s background-color;

  & svg {
    transition: 0.25s transform;
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    inset: 0;
  }
}

.ImageWithFactsViewer__button--mainView {
  @mixin typographyMobileStrongLarge;

  line-height: 1.5rem;
  color: var(--base-grey-black-100);
  padding: var(--spacing-xxs) var(--spacing-xs);
  width: 100%;

  &::after {
    display: none;
  }
}

.ImageWithFactsViewer__singleFact {
  color: var(--base-grey-black-100);
  padding-block: var(--spacing-xxs);
  transition: 0.3s background-color;

  @media (--tablet) {
    padding-block: calc(var(--spacing-xxs) + var(--spacing-xxxs) + (var(--spacing-xxxs) / 2));
  }
}

.ImageWithFactsViewer__singleFactHeader {
  @mixin kogGrid;

  position: relative;

  @media (--mobileOnly) {
    display: flex;
    gap: calc(var(--spacing-xxs) + var(--spacing-xxxs));
  }

  @media (--mobile) {
    gap: var(--spacing-s);
  }
}

.ImageWithFactsViewer__buttonContainer {
  @mixin kogColumn 1;
  @mixin kogTabletColumn 3;

  line-height: 1;
  margin-inline: calc(var(--spacing-xxs) + (var(--spacing-xxxs) / 2)) 0;

  @media (--tablet) {
    margin-inline: calc(var(--spacing-xxs) + var(--spacing-xxxs) + (var(--spacing-xxxs) / 2)) 0;
  }
}

.ImageWithFactsViewer__button--active {
  background-color: var(--base-grey-white);
}

.ImageWithFactsViewer__singleFactContent {
  @mixin kogGrid;

  overflow: hidden;
}

.ImageWithFactsViewer__singleFactTitle {
  @mixin kogColumn 4;
  @mixin kogTabletColumn 9;

  @mixin typographyMobileStrongLarge;

  color: #011520;
  line-height: 1.5rem;
  margin-inline: 0 calc(var(--spacing-xs) + var(--spacing-xxxs));

  @media (--tablet) {
    @mixin typographyDesktopStrongLarge;

    color: var(--base-grey-black-100);
    line-height: 1.5rem;
  }
}

.ImageWithFactsViewer__singleFact--expanded {
  background-color: var(--base-grey-white);

  & .ImageWithFactsViewer__button {
    & svg {
      transform: rotate(45deg);
    }

    @media (--tablet) {
      color: var(--base-grey-black-100);
    }
  }

  & .ImageWithFactsViewer__singleFactTitle {
    @media (--tablet) {
      color: #011520;
    }
  }
}

.ImageWithFactsViewer__singleFactDescription {
  @mixin kogTabletColumnStart 4;

  @mixin typographyMobileDefaultMedium;

  margin-inline: calc(
      var(--spacing-xxs) + (var(--spacing-xxxs) / 2) + 1.5rem + var(--spacing-xxs) +
        var(--spacing-xxxs)
    )
    calc(var(--spacing-xs) + var(--spacing-xxxs));
  margin-block: var(--spacing-xxxs) calc(var(--spacing-xxxs) + (var(--spacing-xxxs) / 2));

  @media (--mobileSmall) {
    margin-left: calc(var(--spacing-xxs) + (var(--spacing-xxxs) / 2) + 1.5rem + var(--spacing-s));
  }

  @media (--tablet) {
    @mixin typographyDesktopDefaultLarge;

    margin-inline: 0 calc(var(--spacing-xs) + var(--spacing-xxxs));
    margin-block: var(--spacing-xs) calc(var(--spacing-xxs) + var(--spacing-xxxs));
  }
}

.ImageWithFactsViewer__imageContainer {
  @mixin kogTabletColumnStart 6;
  @mixin kogTabletColumn 7;

  @mixin kogLaptopColumnStart 7;
  @mixin kogLaptopColumn 6;

  display: grid;
  justify-content: center;

  & img {
    object-fit: contain;
  }

  @media (--tablet) {
    padding-left: 10px;
  }
}
